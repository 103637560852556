import React from 'react';

import { Routes } from '../../utils/routes';

const UserAvatar = ({ imageUrl }) => {
  return (
    <a href={Routes.Dashboard} className="bg-contain bg-no-repeat relative h-[40px] w-[85px] bg-userAvatar hover:bg-userAvatarActive">
      {imageUrl && (
        <img
          className="h-[35px] w-[35px] absolute right-[6px] top-[2px] my-0 rounded-full cursor-pointer border-[3px] border-white shadow-md"
          src={imageUrl}
          alt="authed-user"
        />
      )}
    </a>
  );
};

export default UserAvatar;