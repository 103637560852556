import React, { useMemo } from 'react';
import { Outlet, Navigate, useLocation } from 'react-router-dom';
import { useContainer } from 'unstated-next';
import auth from '../../utils/auth';
import { Routes } from '../../utils/routes';
import { AuthModalMode } from '../../utils/constants';
import { GlobalStateContainer } from '../../provider/GlobalStateContainer';

const PrivateRoute = () => {
  const authenticated = Boolean(auth.getRefreshToken());
  const { setAuthModalState } = useContainer(GlobalStateContainer);
  const { pathname, search } = useLocation();

  const routeWithRedirect = useMemo(() => {
    let url = Routes.Home;
    if (pathname) {
      url += `?redirect_to=${pathname}`;
    }
    if (search) {
      url += search;
    }
    return url;
  }, [pathname, search]);

  return authenticated ? (
    <Outlet />
  ) : (
    <>
      {/* Redirect to the home route with redirect to path */}
      <Navigate to={routeWithRedirect} replace />

      {/* Open the authentication modal with the login mode */}
      {setAuthModalState({ isModalOpen: true, mode: AuthModalMode.LOGIN })}
    </>
  );
};

export default PrivateRoute;
