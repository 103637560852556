import React, { useEffect, useState } from 'react';
import i18n from '../../i18n/i18n';

const VerifyOtpInput = ({
  contactNumber,
  onBack,
  onVerify,
  onResendOtp,
}) => {
  const [counter, setCounter] = useState(120);
  const [verifying, setVerifying] = useState(false);
  const [otp, setOtp] = useState({
    otp1: "",
    otp2: "",
    otp3: "",
    otp4: "",
    otp5: "",
    otp6: ""
  });

  useEffect(() => {
    counter > 0 && setTimeout(() => setCounter(counter - 1), 1000);
  }, [counter]);

  const handleResetOtp = () => {
    setOtp({ otp1: "", otp2: "", otp3: "", otp4: "", otp5: "", otp6: "" });
  }

  const handleOtpChange = (name, value) => {
    setOtp({
      ...otp,
      [name]: value
    });

    if (value !== "") {
      const nextIndex = Number(name.substring(3)) + 1;
      const nextInput = document.getElementById(`otp${nextIndex}`);
      if (nextInput !== null) {
        nextInput.focus();
      } else {
        if (otp.otp1 !== "" && otp.otp2 !== "" && otp.otp3 !== "" && otp.otp4 !== "" && otp.otp5 !== "" && value !== "") {
          const otpCode = otp.otp1 + otp.otp2 + otp.otp3 + otp.otp4 + otp.otp5 + value;
          setVerifying(true);
          onVerify(otpCode);
          handleResetOtp();
        }
      }
    }
  }

  const handleResendOtp = () => {
    onResendOtp();
    setCounter(120);
  }

  return (
    <div className="text-left">
      <p className="ml-[5px] mb-[5px] text-[15px] font-medium">{i18n.t('otp.verification')}</p>
      <div className="flex">
        <p className="ml-[5px] text-[#929292] text-[11px]">{i18n.t('otp.enterOtp', { contactNumber })}.&nbsp;</p>
        <button className="text-[11px] text-[#058483] underline cursor-pointer" onClick={() => { onBack(); setCounter(120) }}>{i18n.t('otp.editDetails')}</button>
      </div>
      <div className="flex flex-row ml-[2px] my-[10px] gap-2 w-full">
        <input
          maxLength={6}
          value={otp.otp1}
          onChange={(e) => {
            const { value } = e.target;
            if (value.length > 1) {
              setOtp({
                otp1: value[0] || '',
                otp2: value[1] || '',
                otp3: value[2] || '',
                otp4: value[3] || '',
                otp5: value[4] || '',
                otp6: value[5] || '',
              });
              if (value.length === 6) {
                onVerify(value);
                document.getElementById('otp6')?.focus();
              }
            } else {
              handleOtpChange('otp1', value);
            }
          }}
          className="w-1/6 py-[8px] px-[12px] text-center border border-[#bfbfbf] rounded-[8px]"
          required
        />
        <input
          id="otp2"
          name="otp2"
          maxLength={1}
          value={otp.otp2}
          onChange={e => handleOtpChange("otp2", e.target.value)}
          className="w-1/6 py-[8px] px-[12px] text-center border border-[#bfbfbf] rounded-[8px]"
          required
        />
        <input
          id="otp3"
          name="otp3"
          maxLength={1}
          value={otp.otp3}
          onChange={e => handleOtpChange("otp3", e.target.value)}
          className="w-1/6 py-[8px] px-[12px] text-center border border-[#bfbfbf] rounded-[8px]"
          required
        />
        <input
          id="otp4"
          name="otp4"
          maxLength={1}
          value={otp.otp4}
          onChange={e => handleOtpChange("otp4", e.target.value)}
          className="w-1/6 py-[8px] px-[12px] text-center border border-[#bfbfbf] rounded-[8px]"
          required
        />
        <input
          id="otp5"
          name="otp5"
          maxLength={1}
          value={otp.otp5}
          onChange={e => handleOtpChange("otp5", e.target.value)}
          className="w-1/6 py-[8px] px-[12px] text-center border border-[#bfbfbf] rounded-[8px]"
          required
        />
        <input
          id="otp6"
          name="otp6"
          maxLength={1}
          value={otp.otp6}
          onChange={e => handleOtpChange("otp6", e.target.value)}
          className="w-1/6 py-[8px] px-[12px] text-center border border-[#bfbfbf] rounded-[8px]"
          required
        />
      </div>
      {verifying ? (
        <p className="text-[11px] ml-[5px] text-[#058483] font-bold">{i18n.t('otp.verifyingOtp')}...</p>
      ) : (
        <>
          {counter === 0 ? (
            <button className="text-[11px] ml-[5px] text-[#058483] underline cursor-pointer" onClick={handleResendOtp}>{i18n.t('otp.resendOtp')}</button>
          ) : (
            <div className="flex gap-1">
              <p className="text-[11px] ml-[5px] text-[#ffa12b]">{i18n.t('otp.codeExpiresIn')} ({counter})</p>
              <p className="text-[11px] text-[#929292] underline">{i18n.t('otp.resendOtp')}</p>
            </div>
          )}
        </>
      )}
    </div>
  );
};

export default VerifyOtpInput;
