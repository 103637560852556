import axios from "axios";
import auth from "./auth";
import { KEYCLOAK_BASE_URL, KEYCLOAK_CLIENT_ID } from "../services/keycloakService";

const axiosApiInstance = axios.create();

export const refreshAccessToken = async () =>
  axios.post(
    `${KEYCLOAK_BASE_URL}/realms/parkit/protocol/openid-connect/token`,
    new URLSearchParams({
      grant_type: "refresh_token",
      client_id: KEYCLOAK_CLIENT_ID,
      refresh_token: auth.getRefreshToken(),
    })
  );

// Request interceptor for API calls
axiosApiInstance.interceptors.response.use((response) => {
  return response
}, async function (error) {
  const originalRequest = error.config;
  if (
    (error?.response?.status === 403 || error?.response?.status === 401) &&
    typeof error.response.data === 'undefined' && 
    !originalRequest._retry
  ) {
    try {
      originalRequest._retry = true;
      const res = await refreshAccessToken();
      const newRequest = {
        ...originalRequest,
        headers: {
          ...originalRequest.headers,
          Authorization: `Bearer ${res.data.access_token}`
        }
      }
      auth.setRefreshToken(res.data.refresh_token);
      
      return axiosApiInstance(newRequest);
    } catch (refreshError) {
      if (refreshError.response.status === 400) {
        auth.clearAppStorage();
        window.location.reload();
      }
    }
  }
  return Promise.reject(error);
});


export default axiosApiInstance;