import React, { useState } from 'react';

const Dropdown = ({ dropdownParent, children }) => {
  const [showDropdown, setShowDropdown] = useState(false);

  const handleDropdownMouseEnter = () => {
    setShowDropdown(true);
  };

  const handleDropdownMouseLeave = () => {
    setShowDropdown(false);
  };

  return (
    <div className="relative hover:text-primary" onMouseEnter={handleDropdownMouseEnter} onMouseLeave={handleDropdownMouseLeave}>
      {dropdownParent}
      {showDropdown && (
        children
      )}
    </div>
  );
};

export default Dropdown;