import { useEffect, useState } from "react";
import { createContainer } from "unstated-next";
import { usePostHog } from "posthog-js/react";
import { AuthModalMode } from "../utils/constants";
import auth from "../utils/auth";
import { getUser } from "../services/userStoreService";
import { createUserWallet, getUserWallet } from "../services/walletService";

function useGlobalState() {
  const posthog = usePostHog();
  const authenticated = Boolean(auth.getRefreshToken());
  const [searchedLocationCoordinates, setSearchedLocationCoordinates] =
    useState(null);
  const [searchLocationRangeInKm, setSearchLocationRangeInKm] = useState(1);
  const [authedUser, setAuthedUser] = useState(null);
  const [authedWallet, setAuthedWallet] = useState(null);
  const [authModalState, setAuthModalState] = useState({
    isModalOpen: false,
    mode: AuthModalMode.LOGIN,
  });

  useEffect(() => {
    async function fetchUserInfoFromAccessToken() {
      const userInfo = await auth.getUserInfoFromJwt();

      if (userInfo) {
        try {
          // user info
          const userResponse = await getUser(userInfo.user_id);
          setAuthedUser(userResponse.data);

          // wallet info
          var walletResponse = await getUserWallet(userInfo.user_id);
          if (walletResponse.data === "") {
            // create wallet if wallet doesn't exist
            await createUserWallet();
            walletResponse = await getUserWallet(userInfo.user_id);
          }
          setAuthedWallet(walletResponse.data);
        } catch (error) {
          console.error("Error fetching user info:", error);
        }
      }
    }

    if (authenticated) {
      fetchUserInfoFromAccessToken();
    }
  }, [authenticated]);

  useEffect(() => {
    if (authenticated && authedUser && posthog) {
      posthog.identify(authedUser.id, {
        name: `${authedUser.firstName} ${authedUser.lastName}`,
        email: authedUser.userName,
      });
    }
  }, [authenticated, authedUser, posthog]);

  return {
    searchedLocationCoordinates,
    setSearchedLocationCoordinates,
    searchLocationRangeInKm,
    setSearchLocationRangeInKm,
    authedUser,
    setAuthedUser,
    authedWallet,
    setAuthedWallet,
    authModalState,
    setAuthModalState,
  };
}

export const GlobalStateContainer = createContainer(useGlobalState);
