import axios from 'axios';

export const KEYCLOAK_BASE_URL = window.REACT_APP_KEYCLOAK_BASE_URL;
export const KEYCLOAK_CLIENT_ID = "parkit-user";

const keycloakService = axios.create({
  baseURL: KEYCLOAK_BASE_URL,
});

export const login = (credentials) => keycloakService.post('/realms/parkit/protocol/openid-connect/token', new URLSearchParams({
  ...credentials,
  grant_type: "password",
  client_id: KEYCLOAK_CLIENT_ID,
}));