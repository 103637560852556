import React, {
  useCallback, useState, forwardRef,
} from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';
import { twMerge } from 'tailwind-merge';

const PasswordInput = forwardRef((props, ref) => {
  const { className, ...restProps } = props;
  const [showPassword, setShowPassword] = useState(false);

  const renderRightIcon = useCallback(
    () => {
      const rightIcon = showPassword ? faEye : faEyeSlash;

      const onClickIcon = () => setShowPassword((prev) => !prev);

      return (
        <button type="button" onClick={onClickIcon}>
          <FontAwesomeIcon icon={rightIcon} />
        </button>
      );
    },
    [showPassword],
  );

  return (
    <div className="flex relative">
      <input className={twMerge(`w-full ${className ?? ''}`)} type={showPassword ? 'text' : 'password'} ref={ref} {...restProps} />
      <div className="absolute right-0 flex items-center inset-y-0 mr-3">
        {renderRightIcon()}
      </div>
    </div>
  );
});

export default PasswordInput;
