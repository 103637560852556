import React, { useEffect, useState } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import { useContainer } from "unstated-next";
import { GlobalStateContainer } from "../../provider/GlobalStateContainer";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleDown, faClose, faSearch } from "@fortawesome/free-solid-svg-icons";

import UserAvatar from "../UserAvatar";
import i18n from '../../i18n/i18n';

import auth from "../../utils/auth";
import { AuthModalMode } from "../../utils/constants";
import { Routes } from "../../utils/routes";

import Logo from "../../images/parkit-logo-horizontal.png";

const MobileMenu = ({
  toggleMenu
}) => {
  return (
    <div className="h-screen w-full fixed top-0 m-auto bg-primary900 text-white z-50 padding-[40px]">
      <div className="relative">
        <div className="absolute top-[20px] right-[20px] cursor-pointer" onClick={toggleMenu}>
          <FontAwesomeIcon icon={faClose} />
        </div>
      </div>
      <nav className="pt-[30px]">
        <ul className="flex flex-col text-center gap-5">
          <li>
            <NavLink to={Routes.Home} className="text-white" onClick={toggleMenu}>
              {i18n.t("navigation.home")}
            </NavLink>
          </li>
          <li>
            <NavLink to={Routes.About} className="text-white" onClick={toggleMenu}>
              {i18n.t("navigation.about")}
            </NavLink>
          </li>
          <li>
            <NavLink to={Routes.Faq} className="text-white" onClick={toggleMenu}>
              {i18n.t("navigation.faq")}
            </NavLink>
          </li>
          <li>
            <NavLink to={Routes.Owners} className="text-white" onClick={toggleMenu}>
              {i18n.t("navigation.owners")}
            </NavLink>
          </li>
          <li>
            <NavLink to={Routes.Parkers} className="text-white" onClick={toggleMenu}>
              {i18n.t("navigation.parkers")}
            </NavLink>
          </li>
          <li>
            <NavLink to={Routes.BusinessSolutions} className="text-white" onClick={toggleMenu}>
              {i18n.t("navigation.businessSolutions")}
            </NavLink>
          </li>
          <li>
            <NavLink to={Routes.BulkListing} className="text-white" onClick={toggleMenu}>
              {i18n.t("navigation.bulkListing")}
            </NavLink>
          </li>
          <li>
            <NavLink to={Routes.BulkRenting} className="text-white" onClick={toggleMenu}>
              {i18n.t("navigation.bulkRenting")}
            </NavLink>
          </li>
          <li>
            <NavLink to={Routes.FindParking} className="text-white" onClick={toggleMenu}>
              {i18n.t("navigation.findAParking")}
            </NavLink>
          </li>
          <li>
            <NavLink to={Routes.AddListing} className="text-white" onClick={toggleMenu}>
              {i18n.t("navigation.listAParking")}
            </NavLink>
          </li>
        </ul>
      </nav>
    </div>
  );
};

const MobileNav = () => {
  const navigate = useNavigate();
  const { searchedLocationCoordinates, authedUser, setAuthModalState } = useContainer(GlobalStateContainer);
  const [displayMenu, setDisplayMenu] = useState(false);
  const [prevScrollpos, setPrevScrollpos] = useState(window.scrollY);
  const [visible, setVisible] = useState(true);
  const authenticated = Boolean(auth.getRefreshToken());

  useEffect(() => {
    const handleScroll = () => {
      const currentScrollPos = window.scrollY;
      setVisible(prevScrollpos > currentScrollPos);
      setPrevScrollpos(currentScrollPos);
    };

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [prevScrollpos]);

  const toggleMenu = () => {
    setDisplayMenu(!displayMenu);
  };


  const handleFindButton = () => {
    if (!searchedLocationCoordinates) {
      alert(`${i18n.t("findParking.chooseLocation")}`);
    } else {
      // Google Ads Tracking
      window.dataLayer = window.dataLayer || [];
      function gtag() { window.dataLayer.push(arguments); }
      gtag('js', new Date());

      gtag('config', 'AW-803504205');
      function gtag_report_conversion(url) {
        gtag('event', 'conversion', {
          'send_to': 'AW-803504205/LgYxCL6JrdcBEM2Akv8C',
          'event_callback': function () { }
        });
        return false;
      }
      gtag_report_conversion();
      navigate(
        `${Routes.FindParking}?lat=${searchedLocationCoordinates.lat}&lng=${searchedLocationCoordinates.lng}`,
        { replace: true }
      );
    }
  };

  return (
    <>
      {displayMenu ? (
        <MobileMenu toggleMenu={toggleMenu} />
      ) : (
        <nav className={`flex justify-between items-center mobile no-underline font-medium w-full px-3 my-auto bg-white text-[#cccccc] z-10 fixed top-0 ${visible ? "" : "hidden"}`}>
          <ul className="h-[75px] cursor-pointer">
            <li className="flex h-full items-center" onClick={toggleMenu}>
              <img className="h-[50px] mr-2" src={Logo} alt="logo" />
              <FontAwesomeIcon icon={faAngleDown} />
            </li>
          </ul>
          <ul className="flex items-center gap-3 sm:gap-5 ml-auto pr-0">
            {window.location.pathname !== "/" && window.location.pathname !== "/find-parking/search" && (
              <button type="button" onClick={handleFindButton} className="text-white bg-gradient-to-r from-[#e76c6d] to-[#b54243] border-none font-medium text-[14px] rounded-[24px] outline-none shadow m-[10px] p-[12px] text-center h-[48x] min-w-[48px]">
                <FontAwesomeIcon icon={faSearch} className="ml-0" />
              </button>
            )}
            {authenticated ? (
              <UserAvatar imageUrl={authedUser?.profilePicture?.url} />
            ) : (
              <>
                <li className="hover:text-primary">
                  <button onClick={() => setAuthModalState({ isModalOpen: true, mode: AuthModalMode.LOGIN })} type="button">{i18n.t("navigation.login")}</button>
                </li>
                <li className="hover:text-primary">
                  <button onClick={() => setAuthModalState({ isModalOpen: true, mode: AuthModalMode.SIGNUP })} type="button">{i18n.t("navigation.register")}</button>
                </li>
              </>
            )}
          </ul>
        </nav>
      )}
    </>
  );
};

export default MobileNav;
