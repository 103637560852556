import { ParkingStatus } from "./constants";

export const humanizeString = (str) => {
  // Replace underscores with spaces
  let humanizedStr = str.replace(/_/g, ' ');

  // Capitalize the first letter of each word
  humanizedStr = humanizedStr.replace(/\b\w/g, function(char) {
    return char.toUpperCase();
  });

  return humanizedStr;
}

export const renderParkingStatus = (status) => {
  switch (status) {
    case ParkingStatus.PRE_ACTIVE:
      return humanizeString(ParkingStatus.PAID);
    case ParkingStatus.PRE_ALLOCATED:
      return humanizeString(ParkingStatus.ALLOCATED);
    default:
      return humanizeString(status);
  }
};

export const cleanUndefined = (value) => {
  return value === "undefined" ? undefined : value;
}

export function centToDollar(dollar = 0) {
  return (dollar / 100).toFixed(2);
}

export function subscriptionIntervalDiscountedAmount(currentAmount, discountPercentage) {
  return discountPercentage > 0 ? currentAmount - (currentAmount * discountPercentage) / 100 : currentAmount;
}