import axios from "axios";
import axiosApiInstance from "../utils/axios";
import auth from "../utils/auth";

const API_BASE_URL = window.REACT_APP_USER_STORE_URL;

const publicUserStoreService = axios.create({
  baseURL: API_BASE_URL,
});

export const forgotPassword = (data) =>
  publicUserStoreService.post("/user-store/v1/users/forgot-password", data);

export const getUser = async (id) =>
  axiosApiInstance.get(`${API_BASE_URL}/user-store/v1/users/${id}`, {
    headers: { Authorization: `Bearer ${await auth.getAccessToken()}` },
  });

export const updateUserInfo = async (id, data) =>
  axiosApiInstance.put(`${API_BASE_URL}/user-store/v1/users/${id}`, data, {
    headers: { Authorization: `Bearer ${await auth.getAccessToken()}` },
  });

export const changePassword = async (id, data) =>
  axiosApiInstance.post(
    `${API_BASE_URL}/user-store/v1/users/${id}/change-password`,
    data,
    { headers: { Authorization: `Bearer ${await auth.getAccessToken()}` } }
  );
