import './App.css';
import axios from 'axios';
import { Suspense, lazy, useEffect } from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { GlobalStateContainer } from './provider/GlobalStateContainer';
import { BreakpointProvider } from 'react-socks';
import ReactGA from 'react-ga4';

import AuthenticationModal from './components/AuthenticationModal';
import CircularProgress from './components/CircularProgress';
import Nav from './components/Nav';
import PrivateRoute from './components/PrivateRoutes';
import i18n from './i18n/i18n';

const HomePage = lazy(() => import('./pages/Home'));
const DashboardPage = lazy(() => import('./pages/Dashboard'));
const InsurancePage = lazy(() => import('./pages/Insurance'));
const BookingPage = lazy(() => import('./pages/Booking'));
const CheckoutPage = lazy(() => import('./pages/Checkout'));
const FindParkingPage = lazy(() => import('./pages/FindParking'));
const AboutPage = lazy(() => import('./pages/About'));
const BlogPage = lazy(() => import('./pages/Blog'));
const BlogPostPage = lazy(() => import('./pages/BlogPost'));
const FAQPage = lazy(() => import('./pages/FAQ'));
const OwnersPage = lazy(() => import('./pages/Owners'));
const ParkersPage = lazy(() => import('./pages/Parkers'));
const ParkingAreaPage = lazy(() => import('./pages/ParkingArea'));
const BusinessSolutionsPage = lazy(() => import('./pages/BusinessSolutions'));
const BulkListingPage = lazy(() => import('./pages/BulkListing'));
const BulkListingEnquiryPage = lazy(() => import('./pages/BulkListingEnquiry'));
const BulkRentingPage = lazy(() => import('./pages/BulkRenting'));
const BulkRentingEnquiryPage = lazy(() => import('./pages/BulkRentingEnquiry'));
const ListAParkingPage = lazy(() => import('./pages/ListAParking'));
const PrivacyPolicyPage = lazy(() => import('./pages/PrivacyPolicy'));
const CancellationPolicyPage = lazy(() => import('./pages/CancellationPolicy'));
const InsurancePolicyPage = lazy(() => import('./pages/InsurancePolicy'));
const ParkerTermsPage = lazy(() => import('./pages/ParkerTerms'));
const PageNotFound = lazy(() => import('./pages/PageNotFound'));
const OwnerTermsPage = lazy(() => import('./pages/OwnerTerms'));
const ReviewsPage = lazy(() => import('./pages/Reviews'));
const JagaAppPage = lazy(() => import('./pages/JagaApp'));
const SetiaGoPage = lazy(() => import('./pages/SetiaGo'));
const TerminateRentingPage = lazy(() => import('./pages/TerminateRenting'));
const TerminateListingPage = lazy(() => import('./pages/TerminateListing'));
const FreezeListingPage = lazy(() => import('./pages/FreezeListing'));
const UnfreezeListingPage = lazy(() => import('./pages/UnfreezeListing'));

const App = () => {
  useEffect(() => {
    const trackingId = "G-NSTLR7LSKC";
    ReactGA.initialize(trackingId);

    const getGeoInfo = () => {
      if (localStorage.getItem('lang')) {
        i18n.changeLanguage(localStorage.getItem('lang'));
      } else {
        axios.get('https://ipapi.co/json/').then((response) => {
          let data = response.data;
          switch (data.country) {
            case 'HK': {
              i18n.changeLanguage('zh-HK');
              break;
            }
            default: {
              i18n.changeLanguage('en');
              break;
            }
          }
        }).catch((error) => {
          console.log(error);
        });
      }
    };
    getGeoInfo();
  }, []);

  return (
    <GlobalStateContainer.Provider>
      <BreakpointProvider>
        <Suspense
          fallback={(
            <div className="h-screen flex justify-center items-center">
              <CircularProgress />
            </div>
          )}
        >
          <BrowserRouter>
            <AuthenticationModal />
            <Nav />
            <Routes>
              <Route index element={<HomePage />} />
              <Route path="/find-parking/search" element={<FindParkingPage />} />
              <Route path="/parking-area/:slug" element={<ParkingAreaPage />} />
              <Route path="/about" element={<AboutPage />} />
              <Route path="/blog" element={<BlogPage />} />
              <Route path="/blog/:id" element={<BlogPostPage />} />
              <Route path="/faq" element={<FAQPage />} />
              <Route path="/faq/:section" element={<FAQPage />} />
              <Route path="/faq/:section/:subcategory" element={<FAQPage />} />
              <Route path="/owners" element={<OwnersPage />} />
              <Route path="/parkers" element={<ParkersPage />} />
              <Route path="/business-solutions" element={<BusinessSolutionsPage />} />
              <Route path="/bulk-listing" element={<BulkListingPage />} />
              <Route path="/bulk-listing-enquiry" element={<BulkListingEnquiryPage />} />
              <Route path="/bulk-renting" element={<BulkRentingPage />} />
              <Route path="/bulk-renting-enquiry" element={<BulkRentingEnquiryPage />} />
              <Route path="/add-listing" element={<ListAParkingPage />} />
              <Route path="/privacy-policy" element={<PrivacyPolicyPage />} />
              <Route path="/cancellation-policy" element={<CancellationPolicyPage />} />
              <Route path="/insurance-policy" element={<InsurancePolicyPage />} />
              <Route path="/parker-terms" element={<ParkerTermsPage />} />
              <Route path="/owner-terms" element={<OwnerTermsPage />} />
              <Route path="/reviews" element={<ReviewsPage />} />
              <Route path="/jaga-app" element={<JagaAppPage />} />
              <Route path="/setia-go" element={<SetiaGoPage />} />
              <Route element={<PrivateRoute />}>
                <Route path="/sign_up" element={<DashboardPage />} />
                <Route path="/dashboard" element={<DashboardPage />} />
                <Route path="/dashboard/:id" element={<DashboardPage />} />
                <Route path="/subscribe-insurance" element={<InsurancePage />} />
                <Route path="/booking/:id" element={<BookingPage />} />
                <Route path="/checkout/:id" element={<CheckoutPage />} />
                <Route path="/terminate-renting/:rentingId" element={<TerminateRentingPage />} />
                <Route path="/terminate-listing/:listingId" element={<TerminateListingPage />} />
                <Route path="/freeze-listing/:listingId" element={<FreezeListingPage />} />
                <Route path="/unfreeze-listing/:listingId/:notifyRentingId?" element={<UnfreezeListingPage />} />
              </Route>
              <Route path="*" element={<PageNotFound />} />
            </Routes>
          </BrowserRouter>
        </Suspense>
      </BreakpointProvider>
    </GlobalStateContainer.Provider>
  );
}

export default App;