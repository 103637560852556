import React, { useEffect, useState } from "react";
import { isAxiosError } from "axios";
import { faClose } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useContainer } from "unstated-next";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import ReactGA from "react-ga4";
import { usePostHog } from "posthog-js/react";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";

import { GlobalStateContainer } from "../../provider/GlobalStateContainer";
import PasswordInput from "../PasswordInput";
import VerifyOtpInput from "../VerifyOtpInput";

import i18n from "../../i18n/i18n";
import { login } from "../../services/keycloakService";
import { forgotPassword } from "../../services/userStoreService";
import { signUp, requestOtp, initCustomer } from "../../services/authService";
import { AuthModalMode } from "../../utils/constants";
import auth from "../../utils/auth";
import { phoneUtil } from "../../utils/phone";
import { Routes } from "../../utils/routes";
import useToasts from "../../hooks/useToasts";
import { useCookies } from "react-cookie";
import { cleanUndefined } from "../../utils/stringFormatter";

const AuthenticationModal = () => {
  const navigate = useNavigate();
  const posthog = usePostHog();
  const [searchParams] = useSearchParams();
  const [cookies, setCookie] = useCookies([]);
  const { authModalState, setAuthModalState } =
    useContainer(GlobalStateContainer);
  const { toastSuccess, toastError } = useToasts();
  const [mode, setMode] = useState(authModalState.mode);
  const [requestedOtp, setRequestedOtp] = useState(false);
  const [hasAgreedTerms, setHasAgreedTerms] = useState(false);
  const [email, setEmail] = useState("");
  const [dateOfBirth, setDateOfBirth] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [contactNumber, setContactNumber] = useState("");
  const [password, setPassword] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [referralUserId, setReferralUserId] = useState(
    cleanUndefined(cookies["referral"])
  );

  useEffect(() => {
    setMode(authModalState.mode);
  }, [authModalState.mode]);

  useEffect(() => {
    const referralParam = searchParams.get("referral");
    if (referralParam) {
      const expires = new Date(Date.now() + 7 * 24 * 60 * 60 * 1000);
      setMode(AuthModalMode.SIGNUP);
      setCookie("referral", referralParam, { expires });
      setReferralUserId(referralParam);
    }
  }, [searchParams, setCookie]);

  const onCloseModal = () => {
    setAuthModalState({ isModalOpen: false, mode: AuthModalMode.LOGIN });
  };

  const requestUserOtp = async () => {
    const requiredFields = [
      email,
      password,
      firstName,
      lastName,
      contactNumber,
    ];
    if (requiredFields.some((field) => !field)) {
      setErrorMessage("All fields are required");
      return;
    }

    let formattedContactNumber = contactNumber;
    if (contactNumber && !contactNumber.startsWith("+")) {
      formattedContactNumber = "+" + contactNumber;
    }
    let phoneNumberProto = phoneUtil.parse(formattedContactNumber, "");
    if (!contactNumber || !phoneUtil.isValidNumber(phoneNumberProto)) {
      setErrorMessage("Invalid contact number");
      return;
    }

    if (!hasAgreedTerms) {
      setErrorMessage("Please agree terms before proceed.");
      return;
    }

    try {
      const response = await requestOtp({
        userName: email,
        contactNumber: formattedContactNumber,
        platform: "PARKIT",
      });

      if (response.status === 204) {
        posthog?.capture('request_otp', {
          email,
          phone: contactNumber,
        });

        toastSuccess("OTP request success! Please check your phone.");
        setRequestedOtp(true);
      }
    } catch (error) {
      if (isAxiosError(error)) {
        toastError(error.response.data.message);
      } else {
        toastError(error.message);
      }
    }
  };

  const signUpUser = async (otpCode) => {
    const requiredFields = [
      email,
      password,
      firstName,
      lastName,
      contactNumber,
    ];
    if (requiredFields.some((field) => !field)) {
      setErrorMessage("All fields are required");
      return;
    }

    let formattedContactNumber = contactNumber;
    if (contactNumber && !contactNumber.startsWith("+")) {
      formattedContactNumber = "+" + contactNumber;
    }
    let phoneNumberProto = phoneUtil.parse(formattedContactNumber, "");
    if (!contactNumber || !phoneUtil.isValidNumber(phoneNumberProto)) {
      setErrorMessage("Invalid contact number");
      return;
    }

    if (!hasAgreedTerms) {
      setErrorMessage("Please agree terms before proceed.");
      return;
    }

    try {
      await signUp({
        userName: email,
        password,
        otpCode,
        customer: {
          referralUserId,
          user: {
            firstName,
            lastName,
            contactNumber: formattedContactNumber,
            birthDay: dateOfBirth,
          },
        },
      });

      ReactGA.event({
        category: "User",
        action: "Created an Account",
      });

      posthog?.capture('sign_up');

      // Google Ads Tracking
      window.dataLayer = window.dataLayer || [];
      function gtag() {
        window.dataLayer.push(arguments);
      }
      gtag("js", new Date());

      gtag("config", "AW-803504205");
      function gtag_report_conversion(url) {
        gtag("event", "conversion", {
          send_to: "AW-803504205/LgYxCL6JrdcBEM2Akv8C",
          event_callback: function () {},
        });
        return false;
      }

      gtag_report_conversion();

      setCookie("referral", null);

      loginUser();
    } catch (error) {
      if (isAxiosError(error)) {
        toastError(error.response.data.message);
      } else {
        toastError(error.message);
      }
    }
  };

  const forgotUserPassword = async () => {
    if (email === "") {
      setErrorMessage("Plase enter your email");
      return;
    }

    try {
      await forgotPassword({
        userName: email,
      });
      posthog?.capture('forgot_password', { email });
      toastSuccess("A reset password email has been sent to you.");
      setMode(AuthModalMode.LOGIN);
    } catch (error) {
      if (isAxiosError(error)) {
        toastError(error.response.data.message);
      } else {
        toastError(error.message);
      }
    }
  };

  const loginUser = async () => {
    if (email === "" || password === "") {
      setErrorMessage("All fields are required");
      return;
    }

    try {
      const response = await login({
        username: email,
        password: password,
      });
      const { refresh_token, access_token } = response.data;
      auth.setRefreshToken(refresh_token);
      auth.setAccessToken(access_token);

      posthog?.capture('login');

      // Init customer to make sure user wallet is created
      await initCustomer();

      onCloseModal();

      const redirectTo = searchParams.get('redirect_to');
      if (redirectTo) {
        navigate(redirectTo, { replace: true });
      } else if (window.location.pathname === "/") {
        navigate(Routes.Dashboard, { replace: true });
      }
    } catch (error) {
      if (isAxiosError(error)) {
        toastError(error.response.data.error_description);
      } else {
        toastError(error.message);
      }
    }
  };

  if (mode === AuthModalMode.SIGNUP) {
    return (
      <div
        aria-hidden="true"
        className={`fixed top-0 left-0 right-0 bottom-0 z-50 w-full p-4 overflow-x-hidden overflow-y-auto max-h-screen ${
          authModalState.isModalOpen ? "" : "hidden"
        }`}
      >
        <div className="flex items-center justify-center min-h-screen text-center max-w-[80%] md:max-w-[500px] mx-auto">
          <div className="fixed inset-0 transition-opacity">
            <div className="absolute inset-0 bg-gray-900 opacity-75" />
          </div>
          <div className="relative bg-white rounded-lg shadow w-full">
            <button
              type="button"
              onClick={onCloseModal}
              className="absolute top-3 right-2.5 text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 ml-auto inline-flex justify-center items-center"
            >
              <FontAwesomeIcon icon={faClose} />
            </button>
            <div className="px-6 py-6 lg:px-8">
              <h3 className="mb-4 text-xl font-medium text-gray-900">
                Welcome to ParkIt
              </h3>
              <div className="space-y-6">
                <div className="flex flex-row w-full gap-3">
                  <div className="w-full">
                    <label
                      htmlFor="firstName"
                      className="block mb-2 text-sm font-medium text-gray-900 text-left"
                    >
                      First name
                    </label>
                    <input
                      name="firstName"
                      onChange={(e) => setFirstName(e.target.value)}
                      className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary focus:border-primary block w-full p-2.5"
                      placeholder="First Name"
                      required
                    />
                  </div>
                  <div className="w-full">
                    <label
                      htmlFor="lastName"
                      className="block mb-2 text-sm font-medium text-gray-900 text-left"
                    >
                      Last name
                    </label>
                    <input
                      name="lastName"
                      onChange={(e) => setLastName(e.target.value)}
                      className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary focus:border-primary block w-full p-2.5"
                      placeholder="Last Name"
                      required
                    />
                  </div>
                </div>
                <div>
                  <label
                    htmlFor="email"
                    className="block mb-2 text-sm font-medium text-gray-900 text-left"
                  >
                    Your email
                  </label>
                  <input
                    type="email"
                    name="email"
                    onChange={(e) => setEmail(e.target.value)}
                    className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary focus:border-primary block w-full p-2.5"
                    placeholder="Email"
                    required
                  />
                </div>
                <div className="text-left">
                  <label
                    htmlFor="contactNumber"
                    className="block mb-2 text-sm font-medium text-gray-900 text-left"
                  >
                    Contact number
                  </label>
                  <PhoneInput
                    name="contactNumber"
                    country={"my"}
                    countryCodeEditable={false}
                    preferredCountries={["my", "sg", "hk", "gb", "us"]}
                    value={contactNumber}
                    onChange={(phone) => setContactNumber(phone)}
                    placeholder={i18n.t("login.contactNumber")}
                    inputProps={{
                      required: true,
                    }}
                    inputClass="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary focus:border-primary block w-full p-2.5"
                    inputStyle={{
                      width: "100%",
                      height: 42,
                      backgroundColor:
                        "rgb(249 250 251 / var(--tw-bg-opacity))",
                    }}
                  />
                </div>
                <div>
                  <label
                    htmlFor="password"
                    className="block mb-2 text-sm font-medium text-gray-900 text-left"
                  >
                    Your password
                  </label>
                  <PasswordInput
                    onChange={(e) => setPassword(e.target.value)}
                    className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary focus:border-primary block w-full p-2.5"
                    placeholder="Password"
                    required
                  />
                </div>
                <div>
                  <label
                    htmlFor="dateOfBirth"
                    className="block mb-2 text-sm font-medium text-gray-900 text-left"
                  >
                    Date of birth
                  </label>
                  <input
                    type="date"
                    name="dateOfBirth"
                    onChange={(e) => setDateOfBirth(e.target.value)}
                    className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary focus:border-primary block w-full p-2.5"
                    placeholder="Date of Birth"
                    required
                  />
                </div>
                {referralUserId && (
                  <p className="text-sm text-slate-400 text-left">
                    {i18n.t("login.referred")}: {referralUserId}
                  </p>
                )}
                {errorMessage && (
                  <p className="text-sm text-red-600 text-left">
                    {errorMessage}
                  </p>
                )}
                <div className="flex items-start">
                  <div className="flex items-center h-5">
                    <input
                      id="agreeTerms"
                      type="checkbox"
                      checked={hasAgreedTerms}
                      onChange={(e) => setHasAgreedTerms(e.target.checked)}
                      className="w-4 h-4 border border-gray-300 rounded text-primary bg-gray-50 focus:ring-3 focus:ring-primary"
                      required
                    />
                  </div>
                  <label
                    htmlFor="agreeTerms"
                    className="ml-2 text-sm font-medium text-gray-900"
                  >
                    {i18n.t("login.read")}{" "}
                    <Link
                      to="/parker-terms"
                      className="text-primary"
                      target="_blank"
                    >
                      {i18n.t("login.parkerTerms")}
                    </Link>{" "}
                    {i18n.t("login.and")}{" "}
                    <Link
                      to="/owner-terms"
                      className="text-primary"
                      target="_blank"
                    >
                      {i18n.t("login.ownerTerms")}
                    </Link>
                  </label>
                </div>
                {requestedOtp ? (
                  <VerifyOtpInput
                    contactNumber={contactNumber}
                    onBack={() => setRequestedOtp(false)}
                    onVerify={signUpUser}
                    onResendOtp={requestUserOtp}
                  />
                ) : (
                  <button
                    type="button"
                    onClick={requestUserOtp}
                    className="w-full text-white bg-primary hover:bg-primary900 font-medium rounded-lg text-sm px-5 py-2.5 text-center"
                  >
                    Sign Up
                  </button>
                )}
                <div className="text-sm font-medium text-gray-500">
                  Already have an account?{" "}
                  <button
                    onClick={() => setMode(AuthModalMode.LOGIN)}
                    className="text-primary hover:underline"
                  >
                    Login
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  if (mode === AuthModalMode.FORGOT_PASSWORD) {
    return (
      <div
        aria-hidden="true"
        className={`fixed top-0 left-0 right-0 bottom-0 z-50 w-full p-4 overflow-x-hidden overflow-y-auto max-h-screen ${
          authModalState.isModalOpen ? "" : "hidden"
        }`}
      >
        <div className="flex items-center justify-center min-h-screen text-center max-w-[80%] md:max-w-[500px] mx-auto">
          <div className="fixed inset-0 transition-opacity">
            <div className="absolute inset-0 bg-gray-900 opacity-75" />
          </div>
          <div className="relative bg-white rounded-lg shadow w-full">
            <button
              type="button"
              onClick={onCloseModal}
              className="absolute top-3 right-2.5 text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 ml-auto inline-flex justify-center items-center"
            >
              <FontAwesomeIcon icon={faClose} />
            </button>
            <div className="px-6 py-6 lg:px-8">
              <h3 className="mb-4 text-xl font-medium text-gray-900">
                Reset Password
              </h3>
              <div className="space-y-6">
                <div>
                  <label
                    htmlFor="email"
                    className="block mb-2 text-sm font-medium text-gray-900 text-left"
                  >
                    Your email
                  </label>
                  <input
                    type="email"
                    name="email"
                    onChange={(e) => setEmail(e.target.value)}
                    className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary focus:border-primary block w-full p-2.5"
                    placeholder="Email"
                    required
                  />
                </div>
                <div className="flex justify-between">
                  <div>
                    {errorMessage && (
                      <p className="text-sm text-red-600 text-left">
                        {errorMessage}
                      </p>
                    )}
                  </div>
                  <button
                    onClick={() => setMode(AuthModalMode.LOGIN)}
                    className="text-sm text-primary hover:underline"
                  >
                    Back to login
                  </button>
                </div>
                <button
                  type="button"
                  onClick={forgotUserPassword}
                  className="w-full text-white bg-primary hover:bg-primary900 font-medium rounded-lg text-sm px-5 py-2.5 text-center"
                >
                  Reset Password
                </button>
                <div className="text-sm font-medium text-gray-500">
                  Don't have an account?{" "}
                  <button
                    onClick={() => setMode(AuthModalMode.SIGNUP)}
                    className="text-primary hover:underline"
                  >
                    Sign up
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div
      aria-hidden="true"
      className={`fixed top-0 left-0 right-0 bottom-0 z-50 w-full p-4 overflow-x-hidden overflow-y-auto max-h-screen ${
        authModalState.isModalOpen ? "" : "hidden"
      }`}
    >
      <div className="flex items-center justify-center min-h-screen text-center max-w-[80%] md:max-w-[500px] mx-auto">
        <div className="fixed inset-0 transition-opacity">
          <div className="absolute inset-0 bg-gray-900 opacity-75" />
        </div>
        <div className="relative bg-white rounded-lg shadow w-full">
          <button
            type="button"
            onClick={onCloseModal}
            className="absolute top-3 right-2.5 text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 ml-auto inline-flex justify-center items-center"
          >
            <FontAwesomeIcon icon={faClose} />
          </button>
          <div className="px-6 py-6 lg:px-8">
            <h3 className="mb-4 text-xl font-medium text-gray-900">
              Welcome to ParkIt
            </h3>
            <div className="space-y-6">
              <div>
                <label
                  htmlFor="email"
                  className="block mb-2 text-sm font-medium text-gray-900 text-left"
                >
                  Your email
                </label>
                <input
                  type="email"
                  name="email"
                  onChange={(e) => setEmail(e.target.value)}
                  className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary focus:border-primary block w-full p-2.5"
                  placeholder="Email"
                  required
                />
              </div>
              <div>
                <label
                  htmlFor="password"
                  className="block mb-2 text-sm font-medium text-gray-900 text-left"
                >
                  Your password
                </label>
                <PasswordInput
                  onChange={(e) => setPassword(e.target.value)}
                  className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary focus:border-primary block w-full p-2.5"
                  placeholder="Password"
                  required
                />
              </div>
              <div className="flex justify-between">
                <div>
                  {errorMessage && (
                    <p className="text-sm text-red-600 text-left">
                      {errorMessage}
                    </p>
                  )}
                </div>
                <button
                  onClick={() => setMode(AuthModalMode.FORGOT_PASSWORD)}
                  className="text-sm text-primary hover:underline"
                >
                  Forgot Password?
                </button>
              </div>
              <button
                type="button"
                onClick={loginUser}
                className="w-full text-white bg-primary hover:bg-primary900 font-medium rounded-lg text-sm px-5 py-2.5 text-center"
              >
                Login
              </button>
              <div className="text-sm font-medium text-gray-500">
                Don't have an account?{" "}
                <button
                  onClick={() => setMode(AuthModalMode.SIGNUP)}
                  className="text-primary hover:underline"
                >
                  Sign up
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AuthenticationModal;
