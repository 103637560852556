import i18n from "i18next";
import { initReactI18next } from "react-i18next";

// importing translation files
import translationEN from "./locales/en.json";
import translationZH from "./locales/zh.json";
import translationZHT from "./locales/zht.json";

// creating object with the variables of imported translation files
const resources = {
  "en": { translation: translationEN }, // English
  "zh": { translation: translationZH }, // Chinese
  "zh-CN": { translation: translationZH }, // Chinese (Simplified)
  "zh-TW": { translation: translationZHT }, // Chinese (Traditional)
  "zh-HK": { translation: translationZHT }, // Chinese (Hong Kong)
  "zh-MO": { translation: translationZHT }, // Chinese (Macao)
};

// i18N initialization
i18n
  .use(initReactI18next)
  .init({
    resources,
    lng: navigator.language,
    fallbackLng: "en", // default language
    interpolation: {
      escapeValue: false,
    },
  });

export default i18n;