/* global google */
import React, { useEffect, useRef, useState } from "react";
import ReactGA from 'react-ga4';
import { useContainer } from "unstated-next";
import { twMerge } from "tailwind-merge";
import { usePostHog } from "posthog-js/react";
import { GlobalStateContainer } from "../../provider/GlobalStateContainer";
import { useNavigate } from "react-router-dom";
import { Routes } from "../../utils/routes";

const SearchFilter = ({
  className,
  inputClassName,
  placeholder,
  handleFocus,
  onPlaceSelected,
}) => {
  const { searchedLocationCoordinates, setSearchedLocationCoordinates } = useContainer(GlobalStateContainer);
  const posthog = usePostHog();
  const [init, setInit] = useState(false);
  const [onPlaceChanged, setOnPlaceChanged] = useState(false);
  const searchFormRef = useRef(null);
  const searchInputRef = useRef(null);
  const navigate = useNavigate();

  useEffect(() => {
    let autocomplete;

    if (!init) {
      let defaultBounds = new google.maps.LatLngBounds(
        new google.maps.LatLng(3.1385035, 101.6167771),
        new google.maps.LatLng(3.2020728, 100.7790663)
      );
      const searchForm = searchFormRef.current;
      const searchInput = searchInputRef.current;

      let att = document.createAttribute("onsubmit");
      att.value = "return false";
      searchForm.setAttributeNode(att);
      autocomplete = new google.maps.places.Autocomplete(
        searchInput,
        {
          types: ["geocode", "establishment"],
          bounds: defaultBounds,
        }
      );

      let pressEnter = false;
      searchInput.addEventListener('keydown', (e) => {
        if (e.keyCode === 40) {
          pressEnter = true;
        }
      });

      google.maps.event.addDomListener(searchInput, 'keydown', (e) => {
        e.cancelBubble = true;
        if (e.keyCode === 13 || e.keyCode === 9) {
          if (!pressEnter && !e.hasRanOnce) {
            google.maps.event.trigger(e.target, 'keydown', {
              keyCode: 40,
              hasRanOnce: true,
            });
          }
        }
      });

      google.maps.event.addListener(autocomplete, "place_changed", function () {
        let place = autocomplete.getPlace();
        const lat = place.geometry.location.lat();
        const lng = place.geometry.location.lng();

        ReactGA.event({
          category: 'Renting',
          action: 'User search location',
          label: place.name
        });

        posthog?.capture('renting_search', { place: place.name, lat, lng });

        if (typeof place.address_components !== 'undefined') {
          pressEnter = false;
        }

        navigate(`${Routes.FindParking}?lat=${lat}&lng=${lng}`);
        setOnPlaceChanged(true);
      });

      setInit(true);
    }
  }, [init, posthog, navigate, setSearchedLocationCoordinates, onPlaceSelected]);

  useEffect(() => {
    if (init && onPlaceChanged && searchedLocationCoordinates && onPlaceSelected) {
      onPlaceSelected();
      // Reset flag
      setOnPlaceChanged(false);
    }
  }, [init, searchedLocationCoordinates, onPlaceChanged, onPlaceSelected]);

  return (
    <form ref={searchFormRef} className={twMerge(`relative flex items-center justify-center ${className ?? ''}`)}>
      <div className={`absolute left-0 ml-[10px] bg-searchIcon bg-contain h-[20px] w-[20px]`} />
      <input
        ref={searchInputRef}
        type="text"
        placeholder={placeholder}
        className={twMerge(`
          box-border border-transparent
          rounded-30 shadow-md outline-none
          focus:ring-0 focus:ring-offset-0 focus:border-primary
          text-sm text-ellipses h-[32px]
          ${inputClassName ?? ''}
        `)}
        onFocus={handleFocus}
      />
    </form>
  );
};


export default SearchFilter;