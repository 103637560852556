import axios from "axios";
import auth from "../utils/auth";
import axiosApiInstance from "../utils/axios";

const API_BASE_URL = window.REACT_APP_API_GATEWAY_URL;

const publicAuthApiService = axios.create({
  baseURL: API_BASE_URL,
});

export const signUp = (data) =>
  publicAuthApiService.post("/parkit/v2/auth/register", data);

export const requestOtp = (data) =>
  publicAuthApiService.post("/parkit/v2/auth/register-otp", data);

export const initCustomer = async () =>
  axiosApiInstance.post(
    `${API_BASE_URL}/parkit/v2/auth/init-customer`,
    {},
    { headers: { Authorization: `Bearer ${await auth.getAccessToken()}` } }
  );
