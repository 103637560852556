import jwtDecode from 'jwt-decode';
import { refreshAccessToken } from "./axios";

const RERESH_TOKEN_KEY = 'refresh-token';

let accessToken = null;

const auth = {
  clearAppStorage() {
    // clear auth tokens
    localStorage.removeItem(RERESH_TOKEN_KEY);
  },
  setRefreshToken(value) {
    localStorage.setItem(RERESH_TOKEN_KEY, value);
  },
  get(key) {
    if (localStorage) {
      return localStorage.getItem(key);
    }
    return null;
  },
  setAccessToken(token) {
    accessToken = token;
  },
  async getAccessToken() {
    if (!accessToken && this.getRefreshToken()) {
      try {
        const res = await refreshAccessToken();
        this.setAccessToken(res.data.access_token)
        return res.data.access_token;
      } catch (error) {
        if (error.response.status === 400) {
          this.clearAppStorage();
        }
      }
    }
    return accessToken;
  },
  async getUserInfoFromJwt() {
    const jwtToken = await this.getAccessToken();
    if (jwtToken) {
      return jwtDecode(jwtToken);
    } else {
      return null;
    }
  },
  getRefreshToken(key = RERESH_TOKEN_KEY) {
    return auth.get(key);
  },
};

export default auth;
