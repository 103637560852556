import axiosApiInstance from "../utils/axios";
import auth from "../utils/auth";

const API_BASE_URL = window.REACT_APP_USER_STORE_URL;

export const getUserWallet = async (userId) =>
  axiosApiInstance.get(`${API_BASE_URL}/wallet/v1/wallets?userId=${userId}`, {
    headers: { Authorization: `Bearer ${await auth.getAccessToken()}` },
  });

export const createUserWallet = async () =>
  axiosApiInstance.post(
    `${API_BASE_URL}/wallet/v1/wallets`,
    {},
    { headers: { Authorization: `Bearer ${await auth.getAccessToken()}` } }
  );

export const updateBankDetails = async (id, data) =>
  axiosApiInstance.put(`${API_BASE_URL}/wallet/v1/wallets/${id}`, data, {
    headers: { Authorization: `Bearer ${await auth.getAccessToken()}` },
  });

export const getCreditCards = async (walletId) =>
  axiosApiInstance.get(
    `${API_BASE_URL}/wallet/v1/wallets/${walletId}/stripe/credit-cards`,
    { headers: { Authorization: `Bearer ${await auth.getAccessToken()}` } }
  );

export const getSetupCardSession = async (walletId, data) =>
  axiosApiInstance.post(
    `${API_BASE_URL}/wallet/v1/wallets/${walletId}/stripe/setup-card`,
    data,
    { headers: { Authorization: `Bearer ${await auth.getAccessToken()}` } }
  );

export const manageBilling = async (walletId, data) =>
  axiosApiInstance.post(
    `${API_BASE_URL}/wallet/v1/wallets/${walletId}/stripe/billing-portal`,
    data,
    { headers: { Authorization: `Bearer ${await auth.getAccessToken()}` } }
  );
